<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="far fa-user"></i> ผู้ใช้งาน </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <accctrl_user/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import accctrl_user from "./view/page.user.vue";

  export default {
    components: {
      accctrl_user : accctrl_user,
    },
    data() {
      return {
      }
    }
  };
</script>
<style>

</style>
